import {NavbarTop} from "../ui_components/navbar_top";
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import classNames from 'classnames';
import { errorMsg, homeUrl, success } from "../utils/common_utils";
import QuestionCodeSubmission from "./question_code_submission";
import IndefiniteProgressBar from "../ui_components/indefinite_progress_bar";
import { QuestionNotesLink } from "./question_notes";
import { getCurrrentLanguage, getUIState, isPremiumUser } from "../login/auth_utils";
import LoginModal from "../login/email_login_modal";
import { BuyPremium, VisitPremiumSection } from "../premium/premium_discounts";
import { LeftIcon } from "../ui_components/common_icons";
import { QuestionSolutions } from "./question_solutions";
import { BRR } from "../ui_components/common_ui_components";

const QuestionDescription = () => {
  //const [code, setCode] = useState('');
  const [question, setQuestion] = useState({});
  const [questionDescription, setQuestionDescription] = useState('');
  const [questionHeading, setQuestionHeading] = useState('');
  //const [input, setInput] = useState('');
  //const [output, setOutput] = useState('');
  // const [loading, setLoading] = useState(false);
  //const[language, setLanguage] = useState(getCurrrentLanguage())
  const[error, setError] = useState('')
  const { questionId } = useParams();
  const[showEditorial, setShowEditorial]=useState(false)
  console.log("question id "+questionId)

 /* const handleSubmit = async () => {
    setLoading(true);
    // Make API request to run code and get output
    setLoading(false);
  };
*/
useEffect(() => {
  console.log("fetching the question description");
  fetch(homeUrl()+"questionsApi/question/"+questionId, {
    method: "GET"
  })
    .then((response) => response.json())
    .then((data) => {
      if(success(data.status)){
        //console.log(" question data fetched "+JSON.stringify(data.question))
        setQuestionDescription(data.description)
        setQuestionHeading(""+data.question.questionId+". "+data.question.heading)
        setQuestion(data.question)
      }
      else{
        setError(data.message || "Error in fetching questions, please try again")
      }
       // console.log("response received")
       // console.log(data);
    })
    .catch((error) => {
      console.log("error occured in fetching all questions "+JSON.stringify(error));
      setError(errorMsg())
    });  
}, []); // empty bracket means there are no dependencies which will make useEffect re run

  return (
  <div>
    <NavbarTop />
   <div className="flex flex-row-responsive overflow-y-auto pt-20 w-screen md:h-screen">
  
    <div className="flex flex-col overflow-y-auto  md:h-screen w-full  md:w-2/5  pl-4 border-gray-400">
    
    { //.... problem statement and editorial button panel div Starts .....
    <div className="flex flex-row mb-4">
    <span className=" px-4">
   <button
      className=" px-4  text-sm border border-gray-500 rounded text-gray-600  hover:text-blue-600 focus:outline-none  focus:ring-blue-600 hover:font-medium"
      onClick={() => setShowEditorial(false)}
    >
      Problem Statement
    </button>
    </span> 

     <span className=" px-4    ">
   <button
      className=" px-4  text-sm border border-gray-500 rounded text-gray-600  hover:text-green-600 focus:outline-none  focus:ring-green-600 hover:font-medium"
      onClick={() => setShowEditorial(true)}
    >
      Editorial (Solutions)
    </button>
    </span>

    </div>
    //.... problem statement and editorial button panel div ends .....
}
     { showEditorial && question && question.questionId>0 &&
      <QuestionSolutions question={question} />
     }

     { // ..... display problem statement section starts  .....
     !showEditorial && 
     <div className="overflow-x-auto whitespace-no-wrap break-before-auto">
     
     { error &&
      <div className="text-red-600 text text-center
      font-medium text-xl italic px-4 py-8">
        {error} &nbsp; ...</div>
      }

      <div className="font-bold text-xl text-gray-800 mx-4"
      dangerouslySetInnerHTML={{ __html: questionHeading }}>
        </div> <br />
      
      {questionDescription.length==0 && 
        <span className="md:ml-32 md:mt-32  z-50"><IndefiniteProgressBar />  </span> 

      }
     
       <div className="text-black mx-4 md:pb-8"
       dangerouslySetInnerHTML={{ __html: questionDescription }}>
        </div>
      <br /> <br />   
      { /*
         false && question && question.thumbnailUrl &&
          <div className='flex flex-row  
          text-center justify-center items-center'>
            <img className='w-1/2'
                src={question.thumbnailUrl}
            />
          </div>
          */
        }
      <BRR /> <BRR /> <br /> <br />
     </div>
     } 
    </div> 
     
     {false && <div className="flex-item w-0.5 h-screen m-2 bg-gray-600"></div>
     }
    <div className="block md:hidden text-center my-4 mx-4 rounded-lg bg-blue-600 text-white p-4 font-bold">
      Please use Laptop/Desktop or any other large screen to add/edit code.
      
    </div>
    {/* the editor will be visible only on laptop/desktop or large screen */}
    <div className="hidden md:block w-full md:w-3/5 md:mx-4 border-gray-800">
    { question && question.heading &&
      <QuestionCodeSubmission question={question} />
    }
    </div>
    <br /> <br />
    </div>
    </div>
  );
  
}

 export default QuestionDescription;

 {/*
 <div className="flex flex-col h-screen">
      <div className="flex-item h-3/4"> 
      <h2 className="text-xl font-bold mb-2">{language} Code Editor:</h2>
          <Editor
            height="500px"
            defaultLanguage={language}
            defaultValue="// Enter your code here"
            value={code}
            onChange={setCode}
          />
       </div>
    <div className="flex-item w-screen h-0.5 m-4 bg-gray-600"></div>
    <div className="flex-item h-1/4"> 
      <h2 className="text-xl font-bold mb-2">Command Line:</h2>
          
      <button
        className={classNames('bg-blue-500 text-white px-4 py-2 rounded-lg', {
          'opacity-50 cursor-not-allowed': loading,
        })}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? 'Running...' : 'Run'}
      </button>
      
    <div className="mt-4 mb-16">
      <h2 className="text-xl font-bold mb-2">Output:</h2>
      <div className="bg-gray-100 p-4 rounded-lg">
        <pre className="text-gray-900">{output}</pre>
      </div>
    </div>
       
       </div>
      </div> 
*/}